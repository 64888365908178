/* ----- Reset css ----- */

* { margin:0px; padding:0px; border:none; outline:none; box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;-o-box-sizing:border-box;}
body {-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;background-color: #1E1E1E; font-family: 'Helvetica-normal';}
img {border:none; outline:none;max-width: 100%;vertical-align: middle;}
a,h1,h2,h3,h4,h5,h6,p,div,img,figure {text-decoration:none; padding:0px; margin:0px;outline: none;}
ul,ol{list-style-type: none;margin:0px;}
input,textarea,select,input[type="text"], input[type="email"], input[type="button"], input[type="submit"] {-webkit-appearance: none;-moz-appearance: none;-ms-appearance: none;-o-appearance: none;appearance: none;}
html {text-size-adjust: none;-ms-text-size-adjust: none;-webkit-text-size-adjust: none;-o-text-size-adjust: none;}
a:hover, a:focus {text-decoration: none;}
/* ::-webkit-input-placeholder {color: #aaaaaa;}
:-moz-placeholder {color: #aaaaaa;opacity: 1;}
::-moz-placeholder {color: #aaaaaa;opacity: 1;}
:-ms-input-placeholder {color: #aaaaaa;} */

h1{font-size: 36px;font-family: 'Helvetica-Medium';}
h2{font-size: 29px;font-family: 'Helvetica-Medium';}
h4{font-size: 22px;font-family: 'Helvetica-Medium';}
p{font-size: 18px;font-family: 'Helvetica-Light';}
/* transition */
a,.toggle-area li,.form-btn
{transition: all 0.4s ease-in-out;-webkit-transition: all 0.4s ease-in-out;-moz-transition: all 0.4s ease-in-out;-o-transition: all 0.4s ease-in-out;}

.left-logo a svg,.match-img img,.sport-img img,.req-img img,.trainee-img img,.details-img img
{width: 100%;height: 100%;}

.get-box,header.scroll-header,.match-img,.sport-box,.section-bg,.goal-box,.model-body , .trainee-img
{box-shadow: 0px 3px 6px #00000029;-o-box-shadow: 0px 3px 6px #00000029;-webkit-box-shadow: 0px 3px 6px #00000029;
    -ms-box-shadow: 0px 3px 6px #00000029;-moz-box-shadow: 0px 3px 6px #00000029;}

section,footer{display: inline-block;width: 100%;}
.main-section{margin-top: 71px;}
/* .container{max-width: 1350px;} */

/* btn */
.link-btn{width: 144px;height: 37px;display: flex;justify-content: center;align-items: center;font-size: 15px;font-family: 'Helvetica-Medium';;
    border: 1px solid #FBAE17; color: #fff;background-color: #FBAE17;border-radius: 3px;}
.link-btn:hover{background-color: transparent;color: #fff;}

/* about */
.about-section{padding: 0 0 50px;}
.main-title h1{margin-bottom: 50px;color: #fff;}
.about-text p{margin-bottom: 30px;color: #F5F5F5;}
.about-text p:last-child{margin-bottom: 0px;}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking
{background-color: transparent;}
.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y 
{width: 6px;}

/* model */

.section-model{display: flex;background-color: rgba(26, 26, 26, 0.76);justify-content: center;align-items: center;
    height: 100vh;position: fixed;top: 0;left: 0;right: 0;width: 100%;z-index: 111;visibility: hidden;}
.section-model.model-open{visibility: visible;}    
.model-body{background-color: #454545;border-radius: 8px;padding: 55px 65px; min-width: 699px;max-height: 572px;
    z-index: 111;position: relative;text-align: center;}
.model-body h2{color: #FCFCFC;margin-bottom:20px;}
.model-body p{color: #FCFCFC;margin-bottom: 25px;font-size: 15px;font-family: 'Helvetica-Light';}
.model-body .form-btn{background-color: #FBAE17;color: #fff;border: 1px solid #FBAE17;transition: none;-webkit-transition: none;
    -o-transition: none;-moz-transition: none;-ms-transition: none;}
/* .model-body .form-btn:hover{background-color: transparent;} */
.section-model.calendar-model .form-btn{background-color: #FBAE17;}
/* calendar */
.calendar-model .react-calendar{margin: 0 auto;background: transparent;border: none;}
.calendar-model .model-body{min-width: auto; width:440px; min-height:560px;padding: 55px 35px 30px;}
.close-btn {position: absolute;right: 20px;top: 15px;width: 13px;height: 25px;display: inline-block;background-color: transparent;}
.lable-text{font-size: 15px; color: #FFFFFF;margin-right: 10px;font-family: 'Helvetica-normal';}
.time-box{border: 1px solid #656363;border-radius: 6px;background-color: transparent;color:#fff;padding: 5px ; position: relative;
    z-index: 1111;}
.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus,
.react-calendar__tile--active,
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus 
{
    background:#FBAE17 !important;border-radius: 5px;
}
.react-calendar__tile--now{background-color: #656363;border-radius: 5px;}
.react-calendar button,.react-calendar__month-view__weekdays__weekday{color: #fff;} 
.calendar-model .react-time-input-picker-wrapper{border: 1px solid #656363 !important;border-radius: 6px;border-bottom-color:#656363 ;}
.calendar-model .react-time-input-picker input{color: #fff;}
.calendar-model .inputWrapper:nth-child(1)::after{color: #fff;}
.calendar-model .react-time-input-picker input:focus{background-color: #FBAE17;}

/* appoitment */
.appointment-model .model-body{min-width: auto; width:360px; min-height:300px;padding: 55px 35px 30px;}
.new-box h3 { color: #FCFCFC;font-family: 'Helvetica-Medium'; margin-bottom: 40px;}
.new-box h4 { color: #FCFCFC;font-family: 'Helvetica-Light'; margin-bottom: 40px;}
.new-box h4 span{display: block;}
.appointment-model .btn{width: 50px; height: 50px;border-radius: 50%;display: flex;align-items: center;justify-content: center;}
.approv-btn{background-color: #53ea46;margin-right: 30px;}
.wrong-btn{background-color: #ea4d46;}
.section-model.bcalendar-model .form-btn{background-color: #ea4d46;border: 1px solid #ea4d46;}
.bcalendar-model .form-btn:hover{background-color: #ea4d46;}

/* result model */
.results-model .result-box{width: 125px;height: 113px;margin: 0 auto; display: block;margin-bottom: 25px;}
.results-model .new-box h4{font-size: 20px;}

.react-datepicker__input-container input{background-color: transparent;padding: 17px 15px 7px;z-index: 1;position: relative;
    color: #fff;width: 100%;}
.react-datepicker-popper{z-index: 11;}

.react-datepicker-popper{z-index: 11;}

 .react-datepicker__navigation{top:13px;}
/* terms and policy */
.rules-section {min-height: 100vh; padding:50px 0;color: #FCFCFC;}
.rules-section .rules-title h1{font-size: 42px;color: #FCFCFC;text-transform: capitalize;font-family: 'Helvetica-Medium';
    margin-bottom: 40px;text-align: center;}
.rules-section .rules-body h2{font-size: 30px;color: #FCFCFC;text-transform: capitalize;font-family: 'Helvetica-Medium';
    margin-bottom: 10px;}
.rules-section .rules-body span{display: inline-block;font-size: 16px;margin-bottom: 15px;font-family: 'Helvetica-normal';}
.rules-section .rules-body p,.addres-box{color: #FCFCFC;font-size: 16px;margin-bottom: 20px;font-family: 'Helvetica-normal';}
.rules-section .rules-body .addres-box{ margin-bottom: 0;}
.rules-section ul{ margin-bottom: 20px;}
.rules-section ul li{display: inline-block;width: 100%;font-size: 16px;margin-bottom: 5px;position: relative; 
    font-family: 'Helvetica-normal'; padding-left: 35px;color: #FCFCFC;}
.rules-section ul li::before{content: '';background-color: #FCFCFC; width: 7px;height: 7px;display: inline-block;border-radius: 50%;
    -webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;-o-border-radius: 50%;position: absolute;top: 7px;left: 15px;right: 0;bottom: 0;}
.rules-section .rules-list li:last-child{margin-bottom: 0;}

/* loader css */
.fmain-section .css-79elbk{display: flex; align-items: center; justify-content: center; flex-wrap: wrap; width: 100%; min-height: 100vh; padding: 10px;}

input[type="text"]:disabled {
    background-color: rgba(239, 239, 239, 0.3) !important;
}
input[type="email"]:disabled {
    background-color: rgba(239, 239, 239, 0.3) !important;
}
input[type="password"]:disabled {
    background-color: rgba(239, 239, 239, 0.3) !important;
}
textarea[type="text"]:disabled {
    background-color: rgba(239, 239, 239, 0.3) !important;
}


.doc__cross{
    width: 15px;
    height: 15px;
    display: inline-block;
    cursor: pointer;
    /* background-color: white; */
  }

  /* delete-cardmodal  */
  .delete-cardmodal .model-body {min-width:auto; width: 450px;}
  .delete-cardmodal .model-body h2 {margin-bottom: 30px;}
 /* data table */
 /* .cDmETx,.kplEDj,.cIQbgV,.hSlPlq,.cfWztq{display: none !important;}
  .ezEBKV,.ecDzaP,.dSYVyP,.cchvzS,.kPXkKA{background-color: transparent !important;color: #fff !important;border-bottom-color:#4E4E4E !important;}
  .kPXkKA{border-top-color:#4E4E4E !important;}
  .iQxEKw,.iOlZKJ:hover{color: #fff !important;fill: #fff !important;}
  .iQxEKw:disabled{cursor: no-drop !important;color: #fff !important;fill: #fff !important;}
  .dJotaH{justify-content: flex-start !important;}
  .rdt_TableCol{width: 16.6% !important;}
  .iOlZKJ{font-size: 14px;font-family: 'Helvetica-Medium';;}
  .iCxiXr{padding: 20px 16px;}
  .iAFSOj{padding: 0 20px !important;} */

/* reacr-big-calender event style */

  
  .rbc-day-slot .rbc-event--requested {
    background-color: #FBAE17;
  }
  
  .rbc-day-slot .rbc-event--accepted {
    background-color: #53EA46;
  }
  .rbc-day-slot .rbc-event--rejected {
    background-color: #EA4646;
  }

  .sc-kEjbQP,.sc-fodVek,.cDmETx,.kplEDj,.cIQbgV,.hSlPlq,.cfWztq{display: none !important;}
  .sc-bdfBQB, .sc-bBXrwG,.sc-dlfnuX,.sc-jrAFXE,.sc-lmoMya,.BBxcU, .ezEBKV,.ecDzaP,.dSYVyP,.cchvzS,.kPXkKA{background-color: transparent !important;color: #fff !important;border-bottom-color:#4E4E4E !important;}
  .kPXkKA{border-top-color:#4E4E4E !important;}
  .sc-bBXrwG,.sc-crrszt,.sc-dlfnuX,.sc-jrAFXE,.sc-iJuVqt,.iQxEKw,.iOlZKJ:hover{color: #fff !important;fill: #fff !important;}
  .sc-iJuVqt:disabled,.iQxEKw:disabled{cursor: no-drop !important;color: #fff !important;fill: #fff !important;}
  .sc-hKgJUU,.dJotaH{justify-content: flex-start !important;}
  /* .domoiu{-webkit-flex-grow: 2 !important; flex-grow: 2 !important;} */
  .rdt_TableCol{width: 16.6% !important;}
  .iOlZKJ{font-size: 14px;font-family: 'Helvetica-Medium';;}
  .iCxiXr{padding: 20px 16px;}
  .iAFSOj{padding: 0 20px !important;}
  
  .calendar-model .react-time-input-picker input{max-width: 38px;}


  .css-1okebmr-indicatorSeparator, .css-14el2xx-placeholder{
    display: none !important;
  }

  #react-select-2-placeholder{
      display: none !important;
  }
  /* css-1s2u09g-control */
  .css-1s2u09g-control{
      background-color: transparent !important;
      border: none !important;
  }

  .option-box{
      background-color: #1E1E1E !important;
      color:white;
  }

  /* .service-section .react-datepicker__input-container::before{
    content: '';
    cursor: pointer;
    background-repeat: no-repeat;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 15px;
    z-index: 1;
    height: 15px;
    display: inline-block;
  } */

  .custom-time-picker{
    font-size: 15px;
    width: 101px
  }

  .custom-time-picker .rc-time-picker-input{
    background: transparent;
    padding: 17px 14px;
    color: white;
  }

  .rbc-timeslot-group {
    min-height: 70px;
  }

  .react-calendar__tile:disabled{
      background-color: #747474;
  }

  .react-calendar__navigation button[disabled]{
    background-color: #747474;
  }