header{background-color: #000;padding: 15px 0;position: fixed;top: 0;left: 0;right: 0;z-index: 111;}
.left-logo a{width: 228px;display: inline-block;position: relative;}
.top-logo a{width: 228px;display: inline-block;position: absolute; top: 35px;left: 25px;}
.navbar li{display: inline-block;margin-right: 55px;}
.navbar li:last-child{margin-right: 10px;}
.navbar a{color: #FCFCFC;font-size: 17px;font-family: 'Helvetica-normal';}
.navbar a:hover{color: #FBAE17;}
.navbar a.active{color: #FBAE17;}
.right-btn{border-left: 1px solid #4e4e4e;display: inline-block;}
.btn-box{padding-left: 30px;}
.btn-box li{display: inline-block;margin-right: 30px;}
.btn-box li:last-child{margin-right: 0;}
.btn-box li a{color: #fff;font-size: 17px;}
.btn-box li a:hover{color: #FBAE17;}
.btn-box li:nth-child(2) a,.btn-box li:nth-child(4) a{width: 120px;height: 38px;display: flex;align-items: center;justify-content: center;
    font-family: 'Helvetica-normal';border:1px solid #FBAE17;color: #fff;}
.btn-box li:nth-child(2) a:hover,.btn-box li:nth-child(4) a{background-color: #FBAE17;}
.btn-box li:nth-child(3) , .btn-box :nth-child(4){display: none;}
.name-btn a{background-color:#FBAE17 ;min-width: 182px;height: 49px;width: 100%;display: flex;justify-content: center;align-items: center;border-radius: 3px;color: #fff !important;}
/* header.scroll-header{} */

/* toggle */
.toggle-area {cursor: pointer;position: relative; display: none;width: 25px;height: 25px;}
.toggle{position: absolute; top: 12px;}
.toggle-area li{display: block;margin-bottom: 5px;background-color: #fff;width: 21px; height: 3px;border-radius: 3px;
    position: absolute;}
.toggle-area.close-btn1 li:nth-child(1),.toggle-area.close-btn1 li:nth-child(2){transform: rotate(45deg);-o-transform: rotate(45deg);-ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);-webkit-transform: rotate(45deg);}
.toggle-area.close-btn1 li:nth-child(3){transform: rotate(-45deg);-o-transform: rotate(-45deg);-ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);-webkit-transform: rotate(-45deg);}
.toggle-area .top{ top: -7px;}
.toggle-area .bottom{top:7px;}
.toggle-area.close-btn1 .top,.toggle-area.close-btn1 .bottom{top: 0;}