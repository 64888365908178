@media (min-width: 1366px){
    .container {max-width: 1350px;}
}
@media (max-width: 1365px){
    .match-list li:nth-child(even) .match-text p{padding-right: 10px;}
    .match-img2 {width: 550px;}
}
@media only screen and (max-width: 1199px) {
    .navbar li {margin-right: 30px;}
    .btn-box li {margin-right: 14px;}
    .btn-box li:nth-child(2) a {width: 105px;height: 35px;}
}
@media only screen and (max-width: 991px) {
    header{padding: 10px 0;}
    header .left-logo a{width: 150px;}
    .navbar li {margin-right: 15px;}
    .navbar{justify-content: unset;}
    .navbar a {font-size: 14px;}
    .btn-box li a{font-size: 14px;}
    .btn-box li:nth-child(2) a {width: 90px;height: 32px;}
    .btn-box {padding-left: 13px;}
    .navbar li:last-child {margin-right: 0px;}
    .footer-menu li {margin-right: 20px;}
    .footer-menu li a{font-size: 14px;}
    .main-section {margin-top: 60px;}
    .main-title h1{margin-bottom: 15px;font-size: 30px;}
    .about-text p{font-size: 15px;}

    .rules-section .rules-title h1 {font-size: 35px;margin-bottom: 35px;}
    .rules-section .rules-body span {font-size: 14px;margin-bottom: 10px;}
    .rules-section .rules-body p, .addres-box {font-size: 13px;margin-bottom: 15px;}
    .rules-section .rules-body h2 {font-size: 25px;margin-bottom: 5px;}
    .rules-section ul li{font-size: 13px;margin-bottom: 3px;}
    .rules-section ul{margin-bottom: 10px;}
 }

@media only screen and (max-width: 1024px) {
    .rules-section .rules-title h1 {font-size: 35px;margin-bottom: 35px;}
    .rules-section .rules-body span {font-size: 14px;margin-bottom: 10px;}
    .rules-section .rules-body p, .addres-box {font-size: 13px;margin-bottom: 15px;}
    .rules-section .rules-body h2 {font-size: 25px;margin-bottom: 5px;}
    .rules-section ul li{font-size: 13px;margin-bottom: 3px;}
    .rules-section ul{margin-bottom: 10px;}
    .main-title h1{margin-bottom: 21px; font-size: 30px;}
    .about-text p{font-size: 15px;}
}
@media only screen and (max-width: 767px) {
    .navbar{display: none;}
    .open-menu.navbar{background-color: #000;width: 100%; position: fixed;top: 61px;left: 0;right: 0;text-align: center;
        padding-left: 0;padding-right: 0;padding-top: 0;z-index: 111;display: block;padding: 0;}
    .navbar li:first-child{border-top: 1px solid #4e4e4e;}
    .navbar li:last-child{border-bottom: none;}    
    .navbar li{width: 100%;margin-right: 0;padding: 7px 0;border-bottom: 1px solid #4e4e4e;}
    .toggle-area{display: block;}
    /* header .left-logo a svg {position: absolute; clip: rect(0px,90px,40px,0px); top: 3px;} */
    header .left-logo a img {position: absolute; clip: rect(0px,35px,40px,0px); top: -20px;}
    .right-btn{justify-content: flex-end;border-left: 0;}
    .footer-menu li{width: 100%;margin-right: 0;margin-bottom: 5px;}
    .footer-menu li:last-child{margin-bottom: 0;}
    .rules-section .rules-title h1 {font-size: 30px;margin-bottom: 25px;}
    .rules-section .rules-body span {font-size: 13px;margin-bottom: 8px;}
    .rules-section .rules-body p, .addres-box {font-size: 13px;margin-bottom: 15px;}
    .rules-section .rules-body h2 {font-size: 20px;margin-bottom: 5px;}
    .about-section {padding: 30px 0;}
    .about-text p{margin-bottom: 10px;font-size: 13px;}
    .main-title h1 {margin-bottom: 10px;font-size: 25px;} 
    .name-btn a { min-width: 115px;height: 40px;}
    header {padding: 15px 0;}
}
@media only screen and (max-width: 575px) {
    header .left-logo a {width: 130px;}
    header .left-logo a svg { clip: rect(0px,78px,40px,0px); top: 3px;}
    .btn-box li{margin-right: 10px;}
    .header .btn-box{padding-right: 5px;}
    .btn-box li:nth-child(4) a {width: 100px;height: 30px;}
    /* .about-section .main-title{margin-top: 20px;} */
    .about-text p{margin-bottom: 10px;font-size: 13px;}
    .about-section{padding: 20px 0 25px;}
    .main-title h1 {margin-bottom: 10px;font-size: 25px;}
    footer{padding: 10px 0;}
    .banner-section.about-banner {background-position: -160px 0;}
    .open-menu.navbar{top: 61px;}
    .calendar-model .model-body{width: 311px;min-height:450px;padding: 43px 15px;}
    .react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after{font-size: 14px;}
    .rules-section .rules-title h1 {font-size: 25px;margin-bottom: 15px;}
    .rules-section {padding:20px 0;}
    .delete-cardmodal .model-body {width: 300px; padding: 40px 30px 30px;}
    .delete-cardmodal .delete-card {margin-left: 20px;margin-top: 0px;}
    .delete-cardmodal .model-body h2{font-size: 25px;}
}